import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { NavBar } from '../../components/segments/NavBar';
import { IndexLayout } from '../../layouts';
import { StaticImage } from 'gatsby-plugin-image';
import { NewAppText, NewAppTitle } from '../../components/ui';
import { theme } from '../../utils/theme';
import { desktopSize, laptopSize, mobileSize } from '../../utils';
import LetsConnectSection from '../../components/segments/LetsConnectSection';
import DarkFooter from '../../components/segments/DarkFooter';
import Mountain from '../../images/work/tapping-solution/mountain.png';
import TestimonyBG from '../../images/work/tapping-solution/testimonial-bg.png';
import MobileTestimonyBG from '../../images/work/tapping-solution/testimonial-bg-mobile.png';
import SwitchProject from '../../components/segments/SwitchProject';
import { Flex } from './imprint';

export default function () {
  return (
    <IndexLayout>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <PageContainer>
        <Hero>
          <HeroContent>
            <Flex direction="column" align="flex-start">
              <StaticImage
                src="../../images/work/tapping-solution/tapping-solution-logo.png"
                alt="Tapping Solution logo"
                width={400}
                placeholder="blurred"
              />
              <HeroText>
                The life-changing Tapping Solution app was created to help users release anxiety and
                quiet racing minds through a combination of acupressure and psychology.
              </HeroText>
            </Flex>
            <HeroImage>
              <StaticImage
                src="../../images/work/tapping-solution/ts-hero-image.webp"
                alt="preview of tapping solutino on a phone with chevrons and books next to it"
                placeholder="blurred"
                style={{ zIndex: 2 }}
              />
            </HeroImage>
          </HeroContent>
        </Hero>
        <BackgroundWrapper>
          <BackgroundImage>
            <AvatarImage bottom="170px" left="-50px">
              <StaticImage
                src="../../images/work/tapping-solution/avatar-1.png"
                alt="standing, calm lady in violet"
                width={425}
                placeholder="blurred"
              />
            </AvatarImage>
            <AvatarImage bottom="140px" right="0px">
              <StaticImage
                src="../../images/work/tapping-solution/avatar-2-cropped.png"
                alt="standing, calm man in violet"
                width={375}
                placeholder="blurred"
              />
            </AvatarImage>
          </BackgroundImage>
          <ChallengeContainer>
            <ContentContainer align="flex-end" justify="space-between" gap={28} maxHeight={750}>
              <WidthContainer minWidth={330}>
                <StaticImage
                  src="../../images/work/tapping-solution/tapping-phone.png"
                  alt="preview of the Tapping Solution app"
                  width={350}
                  placeholder="blurred"
                />
              </WidthContainer>
              <WidthContainer minWidth={300} maxWidth={500}>
                <Flex direction="column" align="flex-start">
                  <TextGrid>
                    <div>
                      <MarginText color={theme.WHITE_COLOR} marginBottom={4}>
                        <b>Industry</b>
                      </MarginText>
                      <NewAppText color={theme.WHITE_COLOR}>Education</NewAppText>
                    </div>
                    <div>
                      <MarginText color={theme.WHITE_COLOR} marginBottom={4}>
                        <b>Technology Utilized</b>
                      </MarginText>
                      <NewAppText color={theme.WHITE_COLOR}>iOS Development</NewAppText>
                      <NewAppText color={theme.WHITE_COLOR}>Web Development</NewAppText>
                    </div>
                  </TextGrid>
                  <Hr />
                  <NewAppTitle color={theme.WHITE_COLOR}>The Challenge</NewAppTitle>
                  <Paragraph>
                    Utilizing Emotional Freedom Techniques (EFT’s), the Tapping Solution is an
                    alternative acupressure therapy treatment that aims to help users lower stress,
                    reduce anxiety, get better sleep, and so much more. With over 2.5 million people
                    already engaging with Tapping Solution's events, courses, and training,
                    "Tapping" wanted to bring their mission and content to life in a digital
                    environment.
                  </Paragraph>
                </Flex>
              </WidthContainer>
            </ContentContainer>
          </ChallengeContainer>

          <SolutionContainer>
            <ReverseContentContainer align="flex-start" justify="space-between" gap={28} >
              <Flex direction="column" align="flex-start">
                <NewAppTitle color={theme.WHITE_COLOR}>The Solution</NewAppTitle>
                <WidthContainer maxWidth={500}>
                  <Paragraph>
                    Rapptr created a genuinely premium end-to-end mobile experience via a
                    self-guided app with hundreds of meditations – putting peace in the hands of
                    people who are looking to lead a happier and healthier life. The 5-star app has
                    now streamed over 8 million meditations and has been downloaded in over 130
                    countries
                  </Paragraph>
                </WidthContainer>
              </Flex>
              <OverlapContainer minWidth={400}>
                <StaticImage
                  src="../../images/work/tapping-solution/ui-cards.png"
                  alt="App extended session preview"
                  width={550}
                  placeholder="blurred"
                />
              </OverlapContainer>
            </ReverseContentContainer>
          </SolutionContainer>
          <MainContainer>
            <ContentContainer align="center" justify="flex-start" maxHeight={750}>
              <WidthContainer minWidth={400}>
                <StaticImage
                  src="../../images/work/tapping-solution/tapping-phone-group.png"
                  alt="two phones showing an audio meditation on the app"
                  width={550}
                  placeholder="blurred"
                  style={{ zIndex: 1 }}
                />
              </WidthContainer>
              <ImpactContent>
                <NewAppTitle color={theme.WHITE_COLOR}>Impact</NewAppTitle>
                <ImpactText>8,000,000+</ImpactText>
                <MarginText color={theme.TAPPING_PRIMARY} marginBottom={28}>
                  <b>Meditations Played</b>
                </MarginText>
                <ImpactText>130+</ImpactText>
                <MarginText color={theme.TAPPING_PRIMARY} marginBottom={28}>
                  <b>Countries</b>
                </MarginText>
                <ImpactText>17,000+</ImpactText>
                <MarginText color={theme.TAPPING_PRIMARY} marginBottom={28}>
                  <b>Reviews</b>
                </MarginText>
              </ImpactContent>
            </ContentContainer>
          </MainContainer>
          <MobileDiv>
            <Flex align="flex-end" justify="center">
              <StaticImage
                src="../../images/work/tapping-solution/avatar-1.png"
                alt="standing, calm lady in violet"
                width={300}
                placeholder="blurred"
              />
            </Flex>
          </MobileDiv>
        </BackgroundWrapper>

        <TestimonyContainer>
          <ContentContainer direction="column" justify="center">
            <Testimony>
              <TestimonyText>
                Rapptr is a partner in all aspects. They are IN the boat with us. I could tell from
                the beginning that Rapptr would be a great partner and help us figure things out.
              </TestimonyText>
              <MarginText mobileMarginBottom={0} marginBottom={0} color={theme.TAPPING_PRIMARY}>
                <b>Kelly Plummer</b>
              </MarginText>
              <MarginText color={theme.TAPPING_PRIMARY}>Director, The Tapping Solution</MarginText>
            </Testimony>
            <ReverseContentContainer justify="space-around" style={{ width: '100%' }}>
              <WidthContainer minWidth={400}>
                <StaticImage
                  src="../../images/work/tapping-solution/testimony-phones.png"
                  alt="preview of a book in the app"
                  width={400}
                  placeholder="blurred"
                />
              </WidthContainer>
              <DownloadContainer>
                <DownloadText>Tapping Meditations to Release Stress and Find Balance</DownloadText>
                <DownloadButtons>
                  <a
                    href="https://apps.apple.com/us/app/the-tapping-solution/id1419815487"
                    target="_blank"
                  >
                    <StaticImage
                      src="../../images/app-store-badge.png"
                      alt="apple app store download button"
                      width={180}
                      height={60}
                      placeholder="blurred"
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/search?q=tapping%20solution&c=apps"
                    target="_blank"
                  >
                    <StaticImage
                      src="../../images/google-play-badge.png"
                      alt="google play store download button"
                      width={200}
                      height={60}
                      placeholder="blurred"
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                </DownloadButtons>
              </DownloadContainer>
            </ReverseContentContainer>
          </ContentContainer>
        </TestimonyContainer>

        <SwitchProject page="tapping-solution" />
      </PageContainer>
      <LetsConnectSection />
      <DarkFooter />
    </IndexLayout>
  );
}

const PageContainer = styled.div`
  position: relative;
  /* min-height: 500px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  background-color: ${theme.WHITE_COLOR};
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;

const Hero = styled.div`
  height: auto;
  min-height: 900px;
  width: 100%;
  background: linear-gradient(rgba(205, 207, 232, 0.83) 0%, #e2e4f3 50%, #fafafa);
  @media ${laptopSize} {
    /* height: 100vh; */
  }
  @media ${mobileSize} {
    min-height: 500px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
  gap: 0px;
  @media ${laptopSize} {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`;

const HeroText = styled(NewAppText as any)`
  margin-top: 80px;
  font-size: 28px;
  line-height: 46px;
  color: ${theme.TAPPING_PRIMARY};
  font-weight: 300;
  letter-spacing: 0;
  @media screen and (max-width: 1100px) {
    font-size: 23px;
  }

  @media ${laptopSize} {
    margin-top: 48px;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
  }
`;

const HeroImage = styled.div`
  min-width: 550px;
  @media ${desktopSize} {
    min-width: 500px;
  }
  @media ${laptopSize} {
    display: flex;
    justify-content: center;
    position: relative;
    left: 20px;
    min-width: 0;
    width: 90vw;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  margin-top: -200px;
  margin-bottom: 150px;
  width: 100%;

  @media ${laptopSize} {
    /* margin-top: -100px; */
    padding-top: 200px;
    padding-bottom: 50px;

    -webkit-clip-path: polygon(15% 0, 100% 2%, 9% 100%, 0 97%, 0 6%);
    clip-path: polygon(15% 0, 100% 2%, 100% 99%, 0 97%, 0 6%);
  }

  @media ${mobileSize} {
    /* margin-top: -100px; */
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: -169px;
  bottom: 0;
  width: 100%;
  height: calc(100% + 169px + 169px);
  object-fit: cover;
  background: url(${Mountain});
  background-size: cover;
  background-position: center;
  -webkit-clip-path: polygon(15% 0, 100% 10%, 100% 85%, 70% 100%, 0 90%, 0 10%);
  clip-path: polygon(15% 0, 100% 8%, 100% 85%, 70% 100%, 0 90%, 0 8%);
  @media ${laptopSize} {
    -webkit-clip-path: polygon(15% 0, 100% 2%, 9% 100%, 0 97%, 0 6%);
    clip-path: polygon(15% 0, 100% 2%, 100% 99%, 0 97%, 0 6%);
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 1100px; */
  /* width: 80%; */
  width: 100%;
  margin: 0 auto;
  color: ${theme.WHITE_COLOR};
`;

const ChallengeContainer = styled(MainContainer)`
  position: relative;
  margin-top: -100px;
  /* margin-bottom: -200px; */
  padding-bottom: 150px;

  @media ${laptopSize} {
    margin-bottom: 0;
  }
`;

const SolutionContainer = styled(MainContainer)`
  position: relative;
  margin-bottom: -100px;
  @media ${laptopSize} {
    margin-bottom: 30px;
  }
`;

interface AbsoluteProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  mobileTop?: string;
  mobileRight?: string;
  mobileBottom?: string;
  mobileLeft?: string;
}
const AbsoluteContainer = styled.div<AbsoluteProps>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : 'initial')};
  right: ${(props) => (props.right ? props.right : 'initial')};
  bottom: ${(props) => (props.bottom ? props.bottom : 'initial')};
  left: ${(props) => (props.left ? props.left : 'initial')};

  @media ${laptopSize} {
    top: ${(props) => (props.mobileTop ? props.mobileTop : 'initial')};
    right: ${(props) => (props.mobileRight ? props.mobileRight : 'initial')};
    bottom: ${(props) => (props.mobileBottom ? props.mobileBottom : 'initial')};
    left: ${(props) => (props.mobileLeft ? props.mobileLeft : 'initial')};
  }
`;

const AvatarImage = styled(AbsoluteContainer)`
  position: absolute;
  @media screen and (max-width: 1400px) {
    width: 275px;
  }
  @media screen and (max-width: 1090px) {
    width: 225px;
  }
  @media ${laptopSize} {
    display: none;
  }
`;

interface WidthProps {
  minWidth?: number;
  maxWidth?: number;
}
const WidthContainer = styled.div<WidthProps>`
  /* width: 100%; */
  min-width: ${(props) => props.minWidth}px;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '90%')};
  height: max-content;
  @media ${laptopSize} {
    display: flex;
    justify-content: center;
    min-width: 80vw;
  }
`;

const OverlapContainer = styled(WidthContainer)`
  z-index: 10;
  @media ${laptopSize} {
    position: relative;
    top: -100px;
  }
`;

const MobileDiv = styled.div`
  display: none;
  margin: 0;
  padding: 0;
  @media ${laptopSize} {
    display: block;
  }
`;

const ContentContainer = styled(Flex)<{ maxHeight?: number }>`
  max-width: 1100px;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : 'none')};
  width: 80%;
  @media ${laptopSize} {
    max-height: none;
  }
`;

const ReverseContentContainer = styled(Flex)`
  max-width: 1100px;
  width: 80%;
  @media ${laptopSize} {
    flex-direction: column-reverse;
    width: 90%;
    gap: 0;
  }
`;

const Paragraph = styled(NewAppText)`
  color: ${theme.WHITE_COLOR};
  font-size: 18px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const TextGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  p {
    margin-bottom: 0;
    font-size: 18px;
  }
  @media ${laptopSize} {
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    margin-top: 60px;
    p {
      text-align: center;
    }
  }
`;

const Hr = styled.hr`
  max-width: 450px;
  height: 2px;
  border-color: ${theme.WHITE_COLOR};
  @media ${laptopSize} {
    width: 80%;
  }
`;

const ImpactContent = styled.div`
  margin-left: 100px;
  z-index: 1;
  div:nth-child(3) > p {
    margin-right: 0px;
  }

  & > h1 {
    margin-bottom: 36px;
  }

  @media ${laptopSize} {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    margin-top: 48px;
    margin-left: 0;
    box-shadow: none;
    h1 {
      text-align: center;
    }
  }
`;

const ImpactText = styled.p`
  /* margin-bottom: 10px; */
  margin-right: 70px;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  color: ${theme.TAPPING_PRIMARY};

  @media ${laptopSize} {
    margin-right: 0;
  }
`;

interface MarginProps {
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  mobileMarginBottom?: number;
}
const MarginText = styled(NewAppText)<MarginProps>`
  margin-bottom: ${({ marginBottom }) => (marginBottom !== undefined ? marginBottom : 16)}px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)}px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;

  @media ${laptopSize} {
    /* margin: 0 auto; */
    text-align: center;
    margin-bottom: ${({ mobileMarginBottom }) =>
      mobileMarginBottom !== undefined ? mobileMarginBottom : 32}px;
  }
`;

const TestimonyContainer = styled(MainContainer)`
  margin-top: -400px;
  padding-top: 500px;
  background: url(${TestimonyBG});
  background-size: cover;
  background-position: 50% 80%;
  @media ${laptopSize} {
    margin-top: -300px;
    padding-top: 100px;
    background: url(${MobileTestimonyBG});
    background-size: cover;
    background-position: 50% 73%;
  }
  /* @media ${mobileSize} {
    background-position: 50% 80%;
  } */
`;

const Testimony = styled.div`
  position: relative;

  ::before {
    content: '“';
    position: absolute;
    left: -100px;
    top: 93px;
    font-family: ${theme.SECONDARY_FONT};
    font-size: 200px;
    font-weight: 700;
    color: ${theme.TAPPING_SECONDARY};
  }
  ::after {
    content: '”';
    position: absolute;
    right: -100px;
    top: 93px;
    font-family: ${theme.SECONDARY_FONT};
    font-size: 200px;
    font-weight: 700;
    color: ${theme.TAPPING_SECONDARY};
  }

  @media ${desktopSize} {
    ::before {
      position: relative;
      left: -10px;
      top: 80px;
    }
    ::after {
      top: auto;
      bottom: -20px;
      right: -10px;
    }
  }

  @media ${laptopSize} {
    ::after {
      top: auto;
      bottom: 10px;
      right: -10px;
    }
  }
`;

const TestimonyText = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  /* font-style: italic; */
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: ${theme.TAPPING_PRIMARY};

  @media ${laptopSize} {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 80px;
  }
`;

const DownloadContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  margin-left: 80px;
  @media ${laptopSize} {
    margin: 0;
    margin-bottom: 40px;
    align-items: center;
  }
  @media ${mobileSize} {
    margin-bottom: 0px;
  }
`;

const DownloadText = styled(NewAppText)`
  margin-bottom: 8px;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: ${theme.WHITE_COLOR};
  text-align: left;
  @media ${laptopSize} {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
`;

const DownloadButtons = styled.div`
  display: grid;
  grid-template-columns: 180px 200px;
  grid-gap: 32px;
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin: 40px;
    /* grid-template-columns: 1fr; */
    /* grid-template-rows: repeat(2, 60px); */
    /* justify-content: center; */
    /* margin-top: 40px; */
  }
  @media ${mobileSize} {
    gap: 20px;
  }
`;
